import Cookies from 'js-cookie';

import { LELAND_DOMAIN } from './constants';

const NEW_EXPERIENCE_KEY = 'leland-NEW_EXPERIENCE';

interface IAutoNewExperience {
  getAutoNewExperience: () => Nullable<boolean>;
  setAutoNewExperience: () => void;
  removeAutoNewExperience: () => void;
}

export const LelandAutoNewExperience: IAutoNewExperience = {
  getAutoNewExperience: () => {
    try {
      return !!Cookies.get(NEW_EXPERIENCE_KEY);
    } catch {
      return null;
    }
  },
  setAutoNewExperience: () =>
    Cookies.set(NEW_EXPERIENCE_KEY, '', {
      domain: LELAND_DOMAIN,
    }),
  removeAutoNewExperience: () =>
    Cookies.remove(NEW_EXPERIENCE_KEY, {
      domain: LELAND_DOMAIN,
    }),
};
