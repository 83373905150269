import { ApolloProvider } from '@apollo/client';
import {
  ModalProvider,
  ToastContextProvider,
} from '@leland-dev/leland-ui-library';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { type NextWebVitalsMetric } from 'next/app';
import { useEffect } from 'react';

import AuthContextProvider from '../context/AuthContext';
import LexContextProvider from '../context/LexContext';
import { useApollo } from '../lib/apolloClient';
import { useAttribution } from '../lib/attribution';
import { useGtm } from '../lib/gtm';
import { init as initNProgress } from '../lib/nprogress';
import { appInsights, reactPlugin } from '../utils/analytics/app-insights';
import mxpnl from '../utils/analytics/mixpanel';
import { usePageView } from '../utils/analytics/usePageView';

/* eslint-disable import/order */
import 'nprogress/nprogress.css'; //styles of nprogress
import '../styles/globals.css';
import { useRouter } from 'next/router';
import { LelandAutoNewExperience } from '../utils/auto-new-experience';
/* eslint-enable import/order */

export function reportWebVitals(metric: NextWebVitalsMetric) {
  appInsights.trackMetric(
    { name: metric.name, average: metric.value },
    {
      url: window.location.href,
    },
  );
}

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  useEffect(() => {
    // @ts-expect-error `lelandVersion` does not exist on `window`
    window.lelandVersion = process.env.NEXT_PUBLIC_LELAND_VERSION;
  }, []);

  const apolloClient = useApollo(pageProps);

  useGtm();

  useAttribution();

  usePageView();

  useEffect(() => {
    initNProgress();
    void mxpnl.init();
  }, []);

  const router = useRouter();
  useEffect(() => {
    if (router.query.autoNewExperience) {
      LelandAutoNewExperience.setAutoNewExperience();
    }
  }, [router.query.autoNewExperience]);

  // Use layout defined at page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <ApolloProvider client={apolloClient}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <AuthContextProvider>
          <LexContextProvider>
            <ToastContextProvider>
              <ModalProvider router={router}>
                {getLayout(<Component {...pageProps} />, pageProps)}
              </ModalProvider>
            </ToastContextProvider>
          </LexContextProvider>
        </AuthContextProvider>
      </AppInsightsContext.Provider>
    </ApolloProvider>
  );
}
